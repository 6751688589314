<template>
  <div class="panel">
    <div class="title">实名认证</div>
    <el-alert
      title="实名信息仅用于认证，我们会严格保障信息安全"
      type="warning">
    </el-alert>
    <el-form :model="form" :rules="rules" label-position="top">
      <el-form-item label="真实姓名" prop="realName">
        <el-input placeholder="请输入真实姓名" type="text" maxlength="10" v-model="form.realName"></el-input>
      </el-form-item>
      <el-form-item label="身份证号码" prop="idNo">
        <el-input placeholder="请输入身份证号码" type="text" v-model="form.idNo"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-if="settingInfo.phone" maxlength="11" type="text" :value="settingInfo.phone" disabled></el-input>
        <el-input v-else maxlength="11" type="number" v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code">
        <div class="flex items-center">
          <el-input placeholder="请输入验证码" maxlength="6" type="number" v-model="form.code"></el-input>
          <div :class="['btn-sms', isSendSms ? 'btn-border':'btn-fill-theme']" @click="sendSms">
            {{isSendSms ? `剩余${countdown}` : '获取验证码'}}
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="btn-submit btn-fill-theme" @click="handleSumbit">提交审核</div>

    <!-- 成功弹窗 -->
    <Popup ref="successPopupRef">
      <div class="success-popup">
        <img src="@/assets/images/icon-success.png">
        <div>提交成功</div>
      </div>
    </Popup>
  </div>
</template>

<script>
import Popup from '@/components/Popup'
import { cashoutSettingApi } from '@/api/user.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      form: {
        realName: '',
        idNo: '',
        phone: '',
        code: ''
      },
      rules: {
        realName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        idNo: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        code: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
      },
      countdown: 60,
      isSendSms: false,
      settingInfo: {}
    }
  },
  created() {
    this.getSettingInfo()
  },
  methods: {
    getSettingInfo() {
      cashoutSettingApi({
        "accountType": 2,
        "act": 0,
        "source": "pc",
      }).then(res => {
        console.log('提现设置-查询',res)
        this.settingInfo = res.data
      }).catch(err => {
        this.$tips({ message: err, type: "error" });
      })
    },
    sendSms() {
      if(this.isSendSms) return
      // if(!this.form.phone) return this.$tips({ message: '请输入手机号', type: "warning" });
      const formData = {
        'act': 2,
        'accountType': 2,
        'source': 'pc',
        'idNo': this.form.idNo,
        'realName': this.form.realName
      }
      if(!this.settingInfo.phone) Object.assign(formData, {phone: this.form.phone})
      console.log('formData', formData)
      cashoutSettingApi(formData).then(res => {
        this.isSendSms = true
        const timer = setInterval(() => {
          this.countdown--
          if(this.countdown == 0) {
            this.isSendSms = false
            this.countdown = 60
            clearInterval(timer)
          }
        }, 1000)
      }).catch(err => {
        this.$tips({ message: err, type: "error" });
      })
    },
    handleSumbit() {
      const formData = {
        'act': 1,
        'accountType': 2,
        'source': 'pc',
        'idNo': this.form.idNo,
        'realName': this.form.realName,
        'code': this.form.code
      }
      if(!this.settingInfo.phone) Object.assign(formData, {phone: this.form.phone})
      console.log('formData', formData)
      cashoutSettingApi(formData).then(res => {
        console.log('设置实名',res)
        this.$store.commit('SET_REALNAME', true)
        this.$tips({ message: '实名成功', type: "success" });
        const timer = setTimeout(() => {
          this.$router.replace('/home')
          clearTimeout(timer)
        }, 1500)
      }).catch(err => {
        this.$tips({ message: err, type: "error" });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}
.btn-sms {
  width: 102px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  margin-left: 8px;
  font-size: 14px;
  cursor: pointer;
}
.btn-submit {
  width: 92px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
}
.btn-border {
  color: #999999;
}
</style>